import type { Pagination } from '@/types/common/pagination.type';
import type { ProjectProductModel } from '@/types/project-product/project-product-model.type';
import type {
  ProductInProjectResponse,
  ProjectListResponse,
  ProjectProductResponse
} from '@/types/project-product/project-product-response.type';

export const projectListMapper = (res: ProjectListResponse): Pagination<ProjectProductModel[]> => {
  if (!res) {
    return {
      contents: [],
      totalElements: 0,
      totalPages: 0,
      first: false,
      last: false,
      page: 0,
      size: 0
    };
  }

  const data: ProjectProductModel[] = res.contents.map((project: ProjectProductResponse) => {
    return {
      id: project.projectId,
      name: project.projectName,
      count: project.productCount,
      planStatus: '',
      createdAt: project.createdAt,
      updatedAt: project.createdAt,
      products: project.products?.map((product: ProductInProjectResponse) => {
        return {
          productNo: product.productNo,
          productName: product.productName,
          productType: product.productType,
          productDetailType: product.productDetailType,
          demoYn: product.demoYn,
          planStatus: product.planStatus,
          saleStatus: product.saleStatus,
          displayYn: product.displayYn,
          deleteRequestStatus: product.deleteRequestStatus,
          restrictStatus: product.restrictStatus,
          createdAt: product.createdAt,
          price: product.price,
          discountRate: product.discountRate,
          verifyStatus: product.verifyStatus
        };
      })
    };
  });

  const { page, size, first, last, totalElements, totalPages } = res;

  return {
    contents: data,
    page,
    size,
    first,
    last,
    totalElements,
    totalPages
  };
};
